import Echo from 'laravel-echo';
import lodash from 'lodash';
import { DateTime, Settings } from 'luxon';
import { Alpine, Livewire } from '../../vendor/livewire/livewire/dist/livewire.esm';
import axios from 'axios';
import Pusher from 'pusher-js';
import * as Popper from '@popperjs/core';
import * as bootstrap from 'bootstrap';
import Swiper from 'swiper/bundle';
import jquery from 'jquery';
import select2 from 'select2';

window._ = lodash;
window.$ = window.jQuery = jquery;
window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.Pusher = Pusher;
window.Popper = Popper;
window.bootstrap = bootstrap;
window.Swiper = Swiper;
select2({
    theme: 'bootstrap-5',
});

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: import.meta.env.VITE_PUSHER_APP_KEY,
    cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
    wsHost: import.meta.env.DEV ? window.location.hostname : import.meta.env.VITE_PUSHER_APP_HOST,
    wsPort: import.meta.env.DEV ? 6001 : 2053,
    wssPort: import.meta.env.DEV ? 6001 : 2053,
    forceTLS: false,
    encrypted: true,
    disableStats: true,
    enabledTransports: ['ws', 'wss'],
});

Settings.defaultLocale = 'nl';
window.DateTime = DateTime;

Alpine.store('time', {
    init() {
        setInterval(() => {
            this.now = DateTime.now();
        }, 1000);
    },

    now: DateTime.now(),
});

Alpine.directive('clipboard', (el) => {
    el.addEventListener('click', () => {
        navigator.clipboard.writeText(el.textContent);
        window.toast('info', 'Tekst gekopieerd naar klembord!');
    });
});

import './player';
import './alpine-easymde.js';

Livewire.start();
document.addEventListener('livewire:init', () => {
    Livewire.hook('request', ({ fail }) => {
        fail(({ status, preventDefault }) => {
            if (status === 403 || status === 500) {
                preventDefault();

                Livewire.navigate('/');
            }
        });
    });
});
